import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom'

type Items = {
  id: number;
  name: string;
  description: string;
  address: string;
  rank: number;
  url: string;
  thumbnail_image: string;
}

const Top = () => {

  const [items, setItems] = useState<Items[]>([]);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [rank, setRank] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = () => {
    setLoading(true);

    let url = process.env.REACT_APP_API_HOST + '/api/eateries/?search=1';

    if(name){ url += '&name=' + name }
    if(description){ url += '&description=' + description }
    if(address){ url += '&address=' + address }
    if(rank){ url += '&rank=' + rank }

    axios.get(url)
      .then(function (response) {
        setLoading(false);
        setItems(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const showRank = (rank: number) => {
    let rankString = '';

    switch(rank){
      case 1:
        rankString = 'C';
        break;
      case 2:
        rankString = 'B';
        break;
      case 3:
        rankString = 'A';
        break;
      default:
        rankString = '-';
    }

    return rankString;
  }

  return (
    <div>
        <div className="section-login-box">
          <Link to='login'>Login</Link>
        </div>

        <div className="section-search-box">
          <input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} />
          <input type="text" placeholder="Description" onChange={(e) => setDescription(e.target.value)} />
          <input type="text" placeholder="Address" onChange={(e) => setAddress(e.target.value)} />
          <select onChange={(e) => setRank(Number(e.target.value))}>
            <option value="">Rank</option>
            <option value="3">A</option>
            <option value="2">B</option>
            <option value="1">C</option>
          </select>
          <button onClick={fetchItems}>Search</button>
        </div>

        <div>
          {loading ?
            <ReactLoading type={'spin'} height={'5%'} width={'5%'} className={'loading-box'} />
          :
            items.length ?
              items.map((item, index) => (
                <div key={index} className="section-item-box">
                  <div className="section-item-image">
                    {item.thumbnail_image ?
                      <img src={item.thumbnail_image} />
                    :
                      <img src="https://eatery.s3.ap-northeast-1.amazonaws.com/images/no_image.jpg" />
                    }
                  </div>
                  <div className="section-item-content">
                    <h2>[{showRank(item.rank)}] <a href={item.url} target="_blank">{item.name}</a></h2>
                    <p>{item.address}</p>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))
            :
              <div className="section-no-item-box">
                <p>No Results</p>
              </div>
          }
        </div>
    </div>
  );
}

export default Top;
