import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './index.css';
import Top from './Top';
import Login from './Login';
import List from './List';
import Regist from './Regist';
import Edit from './Edit';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Top/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/admin/list" element={<List/>}/>
          <Route path="/admin/regist" element={<Regist/>}/>
          <Route path="/admin/edit/:id" element={<Edit/>}/>
        </Routes>
      </BrowserRouter>
    )
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App/>);