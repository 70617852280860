import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom'
import ReactLoading from 'react-loading';

const Edit = () => {

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [rank, setRank] = useState<number>(0);
  const [image, setImage] = useState<FileList | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  const { id } = useParams<{id: string}>();

  useEffect(() => {
    fetchItem();
  }, []);

  const fetchItem = () => {

    setLoading(true);

    let url = process.env.REACT_APP_API_HOST + '/api/eateries/' + id;

    axios.get(url)
      .then(function (response) {
        setLoading(false);
        setName(response.data.name);
        setDescription(response.data.description);
        setAddress(response.data.address);
        setRank(response.data.rank);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadItem = () => {

    setLoading(true);

    let url = process.env.REACT_APP_API_HOST + '/api/eateries/' + id + '/';

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('address', address);
    formData.append('rank', String(rank));

    if(image){ formData.append('thumbnail_image', image[0]); }

    axios.put(url, formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'JWT ' + localStorage.getItem('key')
          },
        }
      )
      .then(function (response) {
        navigate('/admin/list');
      })
      .catch(function (error) {
        setErrorMessage('An error has occured.');
        console.log(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  const deleteItem = () => {

    setLoading(true);

    let url = process.env.REACT_APP_API_HOST + '/api/eateries/' + id + '/';

    const formData = new FormData();

    axios.delete(url,
        {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'JWT ' + localStorage.getItem('key')
          },
        }
      )
      .then(function (response) {
        navigate('/admin/list');
      })
      .catch(function (error) {
        setErrorMessage('An error has occured.');
        console.log(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  return (
    <div>
        <div className="section-edit-menu-box">
          <Link to='/admin/list'> &gt;&gt; Back</Link>
          <button onClick={deleteItem}>Delete</button>
        </div>

        {loading ?
          <ReactLoading type={'spin'} height={'5%'} width={'5%'} className={'loading-box'} />
        :
          <div className="section-regist-item-box">
            <div>
              <input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} />
            </div>
            <div>
              <input type="text" placeholder="Description" onChange={(e) => setDescription(e.target.value)} value={description} />
            </div>
            <div>
              <input type="text" placeholder="Address" onChange={(e) => setAddress(e.target.value)} value={address} />
            </div>
            <div>
              <select onChange={(e) => setRank(Number(e.target.value))} value={rank}>
                <option value="">Please select</option>
                <option value="3">A</option>
                <option value="2">B</option>
                <option value="1">C</option>
              </select>
            </div>
            <div>
              <input type="file" accept="image/jpeg" onChange={(e) => setImage(e.target.files)} />
            </div>
            {errorMessage &&
              <div>
                <span>{errorMessage}</span>
              </div>
            }
            <div>
              <button onClick={uploadItem}>Edit</button>
            </div>
          </div>
        }
    </div>
  );
}

export default Edit;
