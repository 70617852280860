import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading';

const Login = () => {

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  const login = () => {

    setLoading(true);

    let url = process.env.REACT_APP_API_HOST + '/api-auth/';

    axios.post(url,
        {
          username: username,
          password: password
        }
      )
      .then(function (response) {
        localStorage.setItem('key', response.data.access);
        navigate('/admin/list');
      })
      .catch(function (error) {
        setErrorMessage('An error has occured.');
        console.log(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  return (
    <div>
        <div className="section-login-menu-box">
          <Link to='/'> &gt;&gt; Back</Link>
        </div>

        {loading ?
          <ReactLoading type={'spin'} height={'5%'} width={'5%'} className={'loading-box'} />
        :
          <div className="section-login-item-box">
            <div>
              <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div>
              <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </div>
            {errorMessage &&
              <div>
                <span>{errorMessage}</span>
              </div>
            }
            <div>
              <button onClick={login}>Login</button>
            </div>
          </div>
        }
    </div>
  );
}

export default Login;
